import { Box, Container, Grid } from '@mui/material'
import React from 'react'
import { GrandHotel, MetalTech, Omsakthi, Optimists, ZenbedRooms } from './icons/icon'

export const Company = () => {
  const data = [
    <MetalTech />,
    <Omsakthi />,
    <Optimists />,
    <GrandHotel />,
    <ZenbedRooms />
  ]
  return (
    <Box sx={{ py: {xs:3,md:5}, background: "#f8f0ff" }}>
      <Container sx={{ px: { lg: 10, xs: 2, md: 5 } }} maxWidth="xxl" >
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} md={12}>
            <h2 className='company'>OUR HAPPY CLIENTS</h2>
            <Grid container spacing={3} alignItems={"center"} justifyContent={"center"}>
              {data.map((logo, index) => {
                return (
                  <Grid item xs={6} md={2.4} key={index}>{logo}</Grid>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
