import React, { useEffect, useState } from 'react'
import { FirstComponent } from '../components/home'
import Services from '../components/services'
import { Exprience } from '../components/exprience'
import { Clients } from '../components/clients'
import { Testimonials } from '../components/testimonials'
import { Company } from '../components/company'
import { Contact } from '../components/contact'
import { Footer } from '../components/footer'
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { Header } from '../components/heater'

const Home = () => {
  const [scrollTo, setScrollTo] = useState('');
  useEffect(() => {
    setTimeout(() => {
      setScrollTo("")
    }, 1000)
  }, [scrollTo])
  useEffect(() => {
    const data = localStorage.getItem("page");
    setScrollTo(data);
    setTimeout(() => {
      localStorage.removeItem('page')
    }, 1000)
  }, []);
  
  return (
    <>
      <Header setScrollTo={setScrollTo} />
      <Element name={"home"}>
        <FirstComponent />
      </Element>
      <Element name={"services"}>
        <Services />
      </Element>
      <Clients />
      <Element name={"about"}>
        <Exprience />
      </Element>
      <Element name={"testimonals"}>
        <Testimonials />
      </Element>
      <Element name={"clients"}>
        <Company />
      </Element>
      <Element name={"contact"}>
        <Contact />
      </Element>
      <Footer setScrollTo={setScrollTo}/>
      <ScrollComponent scrollTo={scrollTo} />
    </>
  )
}

export default Home

export const ScrollComponent = ({ scrollTo }) => {
  useEffect(() => {
    if (scrollTo) {
      scroller.scrollTo(scrollTo, {
        // duration: 2000,
        delay: 0,
        smooth: true
      });
    }
  }, [scrollTo]);

  return null;
};