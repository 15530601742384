import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HealthCare from './page/health-care';
import Home from './page/home';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/healthcare" element={<HealthCare />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

