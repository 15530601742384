import React from 'react'
import { Footer } from '../components/footer'
import { Care} from '../components/healthCare'
import { Contact } from '../components/contact'
import { Header } from '../components/heater'

const HealthCare = () => {
    return (
        <>
            <Header/>
            <Care/>
            <Contact/>
            <Footer />
        </>
    )
}

export default HealthCare