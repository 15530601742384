import { Box, Button, Container, Grid, TextField } from '@mui/material'
import React, { useState } from 'react'
import emailjs from 'emailjs-com';
import axios from 'axios';

const EMAIL_SERVICE_ID = 'service_g0wl0yr';
const EMAIL_TEMPLATE_ID = 'template_3dlml7f';
const USER_ID = '1mhn5MGOfRvEp0pvA';
const isValidIndianPhoneNumber = (phoneNumber) => {
  // Define a regular expression pattern for a valid 10-digit Indian mobile number.
  const phoneNumberPattern = /^[6789]\d{9}$/;
  return phoneNumberPattern.test(phoneNumber);
};
const initialFormState = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  message: '',
  errors: {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
  },
};
export const Contact = () => {
  const [state, setState] = useState(initialFormState);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let errors = { ...state.errors };

    switch (name) {
      case 'firstName':
        errors.firstName = value.length < 1 ? 'First name is required' : '';
        break;
      case 'phone':
        errors.phone = isValidIndianPhoneNumber(value)
          ? ''
          : 'Invalid phone number (e.g., 9876543210)';
        break;
      case 'email':
        errors.email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
          ? ''
          : 'Invalid email address';
        break;
      default:
        break;
    }

    setState((prevState) => ({
      ...prevState,
      [name]: value,
      errors: errors,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validation logic here
    if (state.errors.firstName || state.errors.lastName || state.errors.phone || state.errors.email) {
      // Handle validation errors, e.g., display error messages
      console.error('Form contains validation errors. Please correct them.');

    } else {
      // Form is valid; you can proceed with form submission or other actions
      console.log('Form is valid and ready for submission');
      const data = {
        service_id: EMAIL_SERVICE_ID,
        template_id: EMAIL_TEMPLATE_ID,
        user_id: USER_ID,
        template_params: {
          from_name: "Kepler techno",
          Name: `${state.firstName} ${state.lastName}`,
          Phone: state.phone,
          Email: state.email,
          message: state.message,
        },
      }
      // const emailData = {
      //   form_name: "Kepler techno",
      //   Name: `${state.firstName} ${state.lastName}`,
      //   Phone: state.phone,
      //   Email: state.email,
      //   message: state.message,
      // };
      // emailjs.sendForm(EMAIL_SERVICE_ID, EMAIL_TEMPLATE_ID, emailData, USER_ID)
      //   .then((result) => {
      //     console.log(result.text);
      //     setState(initialFormState)

      //   }, (error) => {
      //     console.log(error.text);
      //   });
      try {
        const res = await axios.post(' https://api.emailjs.com/api/v1.0/email/send', data);
        console.log(res.data);
        setState(initialFormState);
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <Box className="service" sx={{ py: { xs: 2, md: 3 } }}>
      <Container sx={{ px: { lg: 10, xs: 2, md: 5 } }} maxWidth="xxl" >
        <Grid container>
          <Grid item xs={12} md={6}>
            <h2 className='contact' >CONTACT</h2>
            <p className='contact-desc'>Let’s Work Together</p>
            <p>
              Flat No.3-C, Door No.23A, Mandira Apartments, North Boag Road,  <br />T.Nagar, Chennai, Tamil Nadu-600017
              <br />
              info@keplertechno.com<br />
              Tel: +91 93429 78508
            </p>
          </Grid>

          <Grid item xs={12} md={6}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    variant="standard"
                    value={state.firstName}
                    onChange={handleInputChange}
                    error={Boolean(state.errors.firstName)}
                    helperText={state.errors.firstName}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    variant="standard"
                    value={state.lastName}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    id="phone"
                    name="phone"
                    label="Phone"
                    variant="standard"
                    value={state.phone}
                    onChange={handleInputChange}
                    error={Boolean(state.errors.phone)}
                    helperText={state.errors.phone}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    id="email"
                    name="email"
                    label="Email"
                    variant="standard"
                    value={state.email}
                    onChange={handleInputChange}
                    error={Boolean(state.errors.email)}
                    helperText={state.errors.email}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    id="message"
                    name="message"
                    label="Leave us a message..."
                    multiline
                    variant="standard"
                    sx={{ width: '100%' }}
                    value={state.message}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} mt={2}>
                  <Button type="submit" variant="contained" sx={{ textTransform: "none", backgroundColor: "#9e3ffd", borderRadius: 28 }}>Submit</Button>
                </Grid>
              </Grid>
            </form>
          </Grid>

        </Grid>

      </Container>
    </Box >
  )
}
