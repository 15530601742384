import { Box, Container, Grid } from '@mui/material'
import React from 'react'

export const Testimonials = () => {
  return (
    <Box sx={{ py: 3, background: "#9e3ffd" }}>
      <Container>
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} md={7}>
            <h2 className='testimonials'>TESTIMONIALS</h2>
            <p className='testimonials-para'>
              “ Each member of the Kepler Techno team is technically excellent and
              I am impressed by their definition of "Excellence". This together with their attitude towards integrity makes them a near-shore partner that I can trust. ”</p>
            <h3 className='testimonials'>Nader Alnajjar</h3>
            <p className='testimonials-para'> Optimists </p>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
