import { Box, Container, Grid } from '@mui/material'
import React from 'react'

export const Clients = () => {
    const data = [
        {
            title: "10+",
            desc: "Years of Experience"
        },
        {
            title: "25",
            desc: "Qualified Experts",
        },
        {
            title: "70+",
            desc: "Clients Worldwide",
        },
        {
            title: "4",
            desc: "Intl. Partners",
        }
    ]
    return (
        <Box sx={{ py: { xs: 2, md: 3 }, background: "#16163f" }}>
            <Container>
                <Grid container justifyContent={"center"}>
                    <Grid item xs={12} md={9}>
                        <h2 className='clients-header'>We’re Good with Numbers</h2>
                        <Grid container>
                            {data.map((clients) => {
                                return (
                                    <Grid item xs={6} md={3} key={clients.title}>
                                        <h3 className='clients-title'>{clients.title}</h3>
                                        <p className='clients-desc'>{clients.desc}</p>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
