import { Box, Container, Grid } from '@mui/material'
import React from 'react'
const data = [
    {
        "img": "https://optimists-images.sgp1.cdn.digitaloceanspaces.com/website/Business.webp",
        "title": "Business Growth and Strategy",
        "description": "We assist you in expanding your business and revenue."
    },

    {
        "img": "https://optimists-images.sgp1.cdn.digitaloceanspaces.com/website/Digital.webp",
        "title": "Digital Marketing & Brand Positioning",
        "description": "Our specialized team will help you with online marketing and presence."
    },
    {
        "img": "https://optimists-images.sgp1.cdn.digitaloceanspaces.com/website/Customer.webp",
        "title": "Customer Service",
        "description": "Our experienced, dedicated team will guide you with precise assistance."
    },
    {
        "img": "https://optimists-images.sgp1.cdn.digitaloceanspaces.com/website/analysis.webp",
        "title": "Tool for Data Analysis",
        "description": "With cutting-edge new tools, we help you with the right solutions."
    },
    {
        "img": "https://optimists-images.sgp1.cdn.digitaloceanspaces.com/website/interpretation.webp",
        "title": "AI Data Interpretation",
        "description": "We collect highly relevant data to achieve the maximum ROI."
    }
]

export const Care = () => {
    return (
        <>
            <Box sx={{ background: "#f8f0ff", py: { xs: 2, md: 5 }, mt: 5 }}>
                <Container sx={{ px: { xs: 1, md: 3 }, pb: 5, mt: 4 }} maxWidth="xxl" >
                    <Grid container sx={{ alignItems: "center" }} spacing={3}>
                        <Grid item xs={12} md={6}>
                            <img src={"https://optimists-images.sgp1.cdn.digitaloceanspaces.com/website/healthCare1.webp"} style={{ width: "100%" }} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <h1 className='health'>We thrive to increase your ROI</h1>
                            <p className='health1'>With our expertise and potential, we unlock new gateways for you. </p>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box sx={{ backgroundColor: "#fff", py: { xs: 2, md: 5 }, mt: 5 }}>
                <Container sx={{ px: { xs: 1, md: 3 }, pb: 5, mt: 4 }} maxWidth="xxl" >
                    <Grid container spacing={2} sx={{ alignItems: "baseline", justifyContent: "center" }}>
                        {data.map((services) => {
                            return (
                                <Grid item xs={6} md={2.4} key={services.title} >
                                    <Grid container sx={{ justifyContent: "center" }}>
                                        <Grid item xs={8} md={6} justifyContent={"center"}>
                                            <img src={services.img} alt={services.title} style={{ width: "100%" }} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <h3 className='service-title'>{services.title}</h3>
                                            <p className='service-desc'>{services.description}</p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Container>
            </Box>
            <Box sx={{ backgroundColor: "#f8f0ff", py: { xs: 2, md: 0 }, mt: 5 }}>
                <Container sx={{ px: { xs: 1, md: 5 }, pb: 0, mt: 4 }} maxWidth="xxl" >
                    <Grid container justifyContent={"space-between"} alignItems={"center"}>
                        <Grid item xs={12} md={6.5} xl={6}>
                            <h2>15+ Years of healthcare experience</h2>
                            <p>
                                We aim to make a difference in your pharma business and go beyond your expectations with our skillful

                                team, proper research, and competitor analysis. We adopt ourselves to play the vital role that helps

                                execute our clients' expectations or exceed them. Our part is to implement various interventions - brand

                                positioning, social media marketing, customer care services, retention strategy, online marketing

                                strategy, etc. With our qualified team, we are skilled in boosting access to new patients, and identifying

                                and fixing the existing complications. Ambitious clients who respect our virtue and competence bring the

                                best out of us. We tend to work as an extended part of our client's organization to provide the most

                                satisfactory results.
                            </p>
                        </Grid>
                        <Grid item xs={12} md={5} xl={5}>
                            <img src={"https://optimists-images.sgp1.cdn.digitaloceanspaces.com/website/Hospital%20Staff.webp"} alt={"healthcare experience"} style={{width:"100%"}}/>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}
