import { Box, Container, Grid } from '@mui/material'
import React from 'react'
import './style.css';
import Button from '@mui/material/Button';
export const FirstComponent = () => {
    return (
        <div style={{
            backgroundImage: `url(${"https://static.wixstatic.com/media/84770f_994ffe746a074d1f8a2ec9456a8bf1ff~mv2.png/v1/fill/w_1895,h_938,al_br,q_90,usm_0.66_1.00_0.01,enc_auto/84770f_994ffe746a074d1f8a2ec9456a8bf1ff~mv2.png"})`, height: "100vh", backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display:'flex',
            alignItems:"center"
        }}>
            <Container sx={{ px: { lg: 10, xs: 2, md: 5 } }} maxWidth="xxl" >
                <Grid container>
                    <Grid item xs={12} md={7} >
                        <h1 className='header'>We unlock your potential in every direction.</h1>
                        <Box mt={2}>
                            <p className='child'> We cut through complexity and use the right technology <br/>to shape your sustainable future</p>
                        </Box>
                        <Box mt={3}>
                            <Button variant="contained" sx={{ textTransform: "none", backgroundColor: "#9e3ffd", borderRadius: 28 }}>Learn More</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>

    )
}
