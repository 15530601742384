import React from 'react'
import { Box, Container, Grid } from '@mui/material'
import { AI, Business, Cloud, Security, Technology } from './icons/icon'

const data = [{

    "img": <Technology />,
    "title": "Technology Consulting",
    "description": "We help you to achieve your business objectives using the right technology."
},
{
    "img": <Business />,
    "title": "Business Process Outsourcing",
    "description": "Our specialized competencies will help you cut costs, save time and streamline your work processes."
},
{
    "img": <Cloud />,
    "title": "Cloud",
    "description": "We have extensive cloud adoption experience, allowing us to deliver the best outcomes for your business."
},
{

    "img": <AI />,
    "title": "Data & Artificial Intelligence",
    "description": "We help you to realize the value from your data and leverage it to build your advantage."
},
{
    "img": <Security />,
    "title": "Security",
    "description": "We shield your company from cybersecurity threats that could impact business operations."
}
]

const Services = () => {
    return (
        <Box className="service">
            <Container sx={{ px: { xs: 1,md:3 },pb:5, mt: 4 }} maxWidth="xxl" >
                <h2 className='services'>SERVICES</h2>
                <p className='desc'>Taking Your Business to the Next Level</p>
                <Grid container spacing={2} sx={{ alignItems: "baseline", justifyContent: "center" }}>
                    {data.map((services) => {
                        return (
                            <Grid item xs={6} md={2.4} key={services.title} >
                                <Grid container sx={{ justifyContent: "center" }}>
                                    <Grid item xs={8} md={6} justifyContent={"center"}>
                                        {services.img}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <h3 className='service-title'>{services.title}</h3>
                                        <p className='service-desc'>{services.description}</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            </Container>
        </Box>
    )
}

export default Services