import React from "react";
import { Box, Container, Grid, Stack } from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useNavigate, useLocation } from 'react-router-dom';

const pages = ['Home', 'Healthcare ', 'IT Services', "About", "Testimonals", "Clients", "Contact"];
export const Footer = ({ setScrollTo }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = (pages) => {
        if (location.pathname !== '/') {
            localStorage.setItem('page', pages);
            navigate("/")
        }
        else {
            setScrollTo(pages)
        }

    };
    return (
        <Box
            sx={{
                width: "100%",
                height: "auto",
                pt: 5,
                background: "#ebf6ff"
            }}
        >
            <Container maxWidth="lg">
                <Grid container justifyContent={"center"}>
                    <Grid item xs={6} md={1}>
                        <img src={"https://optimists-images.sgp1.cdn.digitaloceanspaces.com/website/logo.webp"} width={"100%"} alt="KeplerTechno.png" />
                    </Grid>
                    <Grid item xs={12} justifyContent={"center"}>
                        <Stack direction={"row"} justifyContent={"center"} sx={{ display: { xs: "none", md: "flex" } }}>
                            <Stack direction={"row"} gap={2}>
                                <p className='pages' onClick={() => handleClick("home")}>Home</p>
                                <p className='pages' onClick={() => navigate("/healthcare")}>Healthcare</p>
                                <p className='pages' onClick={() => handleClick("services")}>IT Services</p>
                                <p className='pages' onClick={() => handleClick("about")}>About</p>
                                <p className='pages' onClick={() => handleClick("testimonals")}>Testimonals</p>
                                <p className='pages' onClick={() => handleClick("clients")}>Clients</p>
                                <p className='pages' onClick={() => handleClick("contact")}>Contact</p>
                            </Stack>
                        </Stack>
                        <Grid container justifyContent={"center"} sx={{ display: { xs: "flex", md: "none" } }}>

                            <Grid item justifyContent={"center"} display={"flex"} xs={6} key={pages}>
                                <p className='pages' onClick={() => handleClick("home")}>Home</p>
                            </Grid>
                            <Grid item justifyContent={"center"} display={"flex"} xs={6} key={pages}>
                                <p className='pages' onClick={() => navigate("/healthcare")}>Healthcare</p>
                            </Grid>
                            <Grid item justifyContent={"center"} display={"flex"} xs={6} key={pages}>
                                <p className='pages' onClick={() => handleClick("services")}>IT Services</p>
                            </Grid>
                            <Grid item justifyContent={"center"} display={"flex"} xs={6} key={pages}>
                                <p className='pages' onClick={() => handleClick("about")}>About</p>
                            </Grid>
                            <Grid item justifyContent={"center"} display={"flex"} xs={6} key={pages}>
                                <p className='pages' onClick={() => handleClick("testimonals")}>Testimonals</p>

                            </Grid>
                            <Grid item justifyContent={"center"} display={"flex"} xs={6} key={pages}>
                                <p className='pages' onClick={() => handleClick("clients")}>Clients</p>

                            </Grid>
                            <Grid item justifyContent={"center"} display={"flex"} xs={6} key={pages}>
                                <p className='pages' onClick={() => handleClick("contact")}>Contact</p>

                            </Grid>
                        </Grid>

                        <Stack direction={"row"} gap={1} justifyContent={"center"}>
                            {/* <FacebookIcon />
                            <TwitterIcon />
                            <InstagramIcon /> */}
                            <a href="https://www.linkedin.com/company/82492911/" target="_blank" rel="noopener noreferrer">
                                <LinkedInIcon sx={{ cursor: 'pointer',color:'#000' }} />
                            </a>
                        </Stack>
                    </Grid>
                </Grid>
                <Box py={2}>
                    <p className="copy">© 2023 Kepler Techno Private Ltd.</p>
                </Box>
            </Container>
        </Box>
    );
};
