import { Box, Container, Grid } from '@mui/material'
import React from 'react'

export const Exprience = () => {
    return (
        <Box > 
            <Grid container alignItems={"center"}>
                <Grid item xs={12} md={6}>
                    <Container>
                        <Grid container justifyContent={'center'}>
                            <Grid item xs={12} md={8}>
                                <h2 className='expriences'>About us</h2>
                                <p className='expriences-desc'>10+ Years of experience</p>
                                <p>We are an independent IT consultancy, originally set up in 2012, operating across India, Europe, US, UAE with over 25 highly qualified people delivering value for our clients. We have been working with clients from many sectors. Healthcare, Biotechnology, Construction, Travel, Hospitality, are some of them. We are Digital Transformation Specialists who strive to be a partner of choice.</p>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
                <Grid item xs={12} md={6}>
                    <img src="https://optimists-images.sgp1.cdn.digitaloceanspaces.com/website/expriences.webp" style={{width:"100%",maxHeight:"350px",objectFit:"cover"}} alt="Expriences" />
                </Grid>
            </Grid>
        </Box>
    )
}
