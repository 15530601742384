import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Stack } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate, useLocation } from 'react-router-dom';
const drawerWidth = 240;



const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));



export const Header = ({ setScrollTo }) => {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = (pages) => {
        if (location.pathname !== '/') {
            localStorage.setItem('page', pages);
            navigate("/")
        }
        else{
            setScrollTo(pages)
            setOpen(false);
        }
       
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    return (
        <AppBar position='fixed' sx={{ background: "#fff", zIndex: 100, width: "100%", boxShadow: 0 }}>
            <Container maxWidth="xxl">
                <Stack direction={"row"} sx={{ alignItems: "center", justifyContent: "space-between" }}>
                    <Stack>
                        <img src={"https://optimists-images.sgp1.cdn.digitaloceanspaces.com/website/logo.webp"} width={"130px"} alt="KeplerTechno.png" />
                    </Stack>
                    <Stack direction={"row"} justifyContent={"center"} sx={{ display: { xs: "none", md: "flex" } }}>
                        <Stack direction={"row"} gap={2}>
                            <p className='pages' onClick={() => handleClick("home")}>Home</p>
                            <p className='pages' onClick={() => navigate("/healthcare")}>Healthcare</p>
                            <p className='pages' onClick={() => handleClick("services")}>IT Services</p>
                            <p className='pages' onClick={() => handleClick("about")}>About</p>
                            <p className='pages' onClick={() => handleClick("testimonals")}>Testimonals</p>
                            <p className='pages' onClick={() => handleClick("clients")}>Clients</p>
                            <p className='pages' onClick={() => handleClick("contact")}>Contact</p>
                        </Stack>
                    </Stack>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Button href='tel:+919342978508' variant="contained" sx={{ textTransform: "none", backgroundColor: "#9e3ffd", borderRadius: 28 }}>Call Now</Button>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            color="#c1c1c1"
                            aria-label="open drawer"
                            edge="end"
                            onClick={handleDrawerOpen}
                            sx={{ ...(open && { display: 'none' }) }}
                        >
                            <MenuIcon />
                        </IconButton>



                    </Box>
                </Stack>
            </Container>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                    },
                }}
                variant="persistent"
                anchor="right"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => handleClick("home")}>
                            Home
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/healthcare")}>
                            Healthcare
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => handleClick("services")}>
                            IT Services
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => handleClick("about")}>
                            About
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => handleClick("testimonals")}>
                            Testimonals
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => handleClick("clients")}>
                            Clients
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => handleClick("contact")}>
                            Contact
                        </ListItemButton>
                    </ListItem>
                </List>
                <Divider />
                <List>
                    <ListItem disablePadding>
                        <Button href='tel:+919342978508' variant="contained" sx={{ textTransform: "none", backgroundColor: "#9e3ffd", borderRadius: 28 }}>Call Now</Button>
                    </ListItem>

                </List>
            </Drawer>
        </AppBar>
    );
}
